
require('es6-promise').polyfill()
export default {
  name: 'JbNav',
  computed: {
    navItems: vm => vm.$store.state.navItems || []
  },
  methods: {
    async logout () {
      this.hide()
      await this.$auth.logout()
      await this.$laravel.setPermissions([])
      await this.$laravel.setRoles([])
      this.$router.push('/')
    },
    hide () {
      if (this.$refs.navCollapse?.$refs?.menuSidebar?.hide) {
        this.$refs.navCollapse.$refs.menuSidebar.hide()
      }
    }
  }
}
